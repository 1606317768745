// @ts-check
/// <reference lib="webworker" />

/**
 * The JSON we get from the PushEvent data
 * @typedef {Object} EventData
 * @property {string} title - Indicates whether the Courage component is present.
 * @property {string} body - Indicates whether the Courage component is present.
 * @property {string} icon - Indicates whether the Courage component is present.
 */

/**
 * @param {PushEvent} event - Foo
 * @returns void
 */
function pushNotificationHandler(event) {
  const self = /** @type {ServiceWorkerGlobalScope} */ (
    /** @type {unknown} */ (globalThis.self)
  );

  const notificationData = event.data?.json();
  void self.registration.showNotification(notificationData.title, {
    body: notificationData.body,
    icon: notificationData.icon,
    tag: notificationData.tag,
  });
}

self.addEventListener("fetch", function (event) {
  event.respondWith(
    caches.open("mysite-dynamic").then(async (cache) =>
      cache.match(event.request).then(
        (response) =>
          response ||
          fetch(event.request).then((response) => {
            // Create synthetic request
            if (event.request.url.includes("forecast")) {
              const url = new URL(event.request.url);
              const beginning = event.request.url.indexOf("/forecast");
              const synthetic = event.request.url.slice(0, beginning);

              cache.put(`${synthetic}/daily`, response.clone());
            } else {
              cache.put(event.request, response.clone());
            }
            return response;
          }),
      ),
    ),
  );
});

self.addEventListener("push", pushNotificationHandler);
